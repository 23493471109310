/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				window.PageFunctions.smoothScroll();
				// JavaScript to be fired on all pages
				window.MenuFunctions.setupMenuToggle();
				window.MenuFunctions.setupSubMenuToggle();

				$(".c-mobile-trigger").click(function(e){
					e.preventDefault();
					$('.c-mobile-trigger').toggleClass('open');
					$('.c-mobile-menu').toggleClass('open');
				});

				$('.c-carousel--slider').slick({
					dots : false,
					autoplay : false,
					infinite: true,
					slidesToShow : 3,
					slidesToScroll : 1,
					centerMode : true,
					centerPadding : '0px',
					responsive: [
						{
							breakpoint: 900,
							settings: {
								slidesToShow: 1
							}
						},
					]
				});

				var initScroll = $(window).scrollTop();
				var scrollPoint = $('.c-banner-bottom').offset().top;
				var scrollTarget = $('.c-header');

				if ( initScroll >= 150 ) {
					$(scrollTarget).addClass('scroll-first');
				} 

				if ( initScroll >= scrollPoint ) {
					$(scrollTarget).addClass('scroll-second');
				} 

				$(window).scroll(function() {    
					var scroll = $(window).scrollTop();
					if ( scroll >= 150 ) {
						$(scrollTarget).addClass('scroll-first');
					} else if ( scroll < 150 ) {
						$(scrollTarget).removeClass('scroll-first');
					}
					if ( scroll >= scrollPoint ) {
						$(scrollTarget).addClass('scroll-second');
					} else if ( scroll < scrollPoint ) {
						$(scrollTarget).removeClass('scroll-second');
					}
				});

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


// ----- transition form js starts here -----
jQuery(document).on('gform_post_render', function( event, form_id, current_page ){

	// we only want this code to be applied to forms with the 'transition-form' class
	if ( jQuery('#gform_wrapper_' + form_id).hasClass('transition-form_wrapper') ) {

		// additionally, we only want the code to be applied to a form one, so we're targeting the form's ID
		var this_form = '#gform_' + form_id;

		// create the dots navigation container
		jQuery(this_form + ' .gform_fields').before('<ul class="transition-form--nav"></ul>');

		// creating the dots navigation items, next buttons, moving the submit button
		jQuery(this_form + ' .gform_fields li').each(function(index){
			var total = jQuery(this).parent().children().length;
			var step = index + 1;
			var dest = index + 2;
			jQuery(this).attr('data-step','step-' + step);

			if (index === total - 1) {
				// move the submit button to the last field
				jQuery(this).parents('.transition-form').find('.gform_button').appendTo(jQuery(this));
			} else {
				// otherwise add a "next step" button
				jQuery(this).append('<button class="transition-form--next" data-dest="step-' + dest + '"></button>');
			}

			if ( index === 0 ) {
				// add active class to first item
				jQuery(this).addClass('active');

				// check if it has validation errors, then assign classes to the dots nav
				if ( jQuery(this).hasClass('gfield_error')) {
					nav_class = 'active nav-error';
				} else {
					nav_class = 'active';
				}
				jQuery(this).parent().siblings('.transition-form--nav').append('<li data-nav="step-' + step + '" class="' + nav_class + '"></li>');
			} else {
				// add inactive class to subsequent items
				jQuery(this).addClass('inactive');

				// check if it has validation errors, then assign classes to the dots nav
				if ( jQuery(this).hasClass('gfield_error')) {
					nav_class = 'inactive nav-error';
				} else {
					nav_class = 'inactive';
				}
				jQuery(this).parent().siblings('.transition-form--nav').append('<li data-nav="step-' + step + '" class="' + nav_class + '"></li>');
			}
		});

		// when you click the next buttons, move to the next field
		jQuery(this_form + ' .transition-form--next').on('click',function(e){
			e.preventDefault();
			jQuery(this).parent().removeClass('active');
			jQuery(this).parent().next().addClass('active');

			// then update the dots nav active states
			var go_to = jQuery(this).data('dest');
			jQuery(this_form + ' .transition-form--nav li').removeClass('active').addClass('inactive');
			jQuery(this_form + ' .transition-form--nav').find('[data-nav="' + go_to  + '"]').removeClass('inactive').addClass('active');
		});

		// when you click the dots navigation, move to that field
		jQuery(this_form + ' .transition-form--nav li').on('click',function(e){
			// remove the active class from the other items, add it to the clicked one
			jQuery(this).siblings().removeClass('active').addClass('inactive');
			jQuery(this).removeClass('inactive').addClass('active');

			// then update the field active states
			var nav = jQuery(this).data('nav');
			console.log(nav);
			jQuery(this_form + ' .gform_fields li').removeClass('active').addClass('inactive');
			jQuery(this_form + ' .gform_fields').find('[data-step="' + nav  + '"]').removeClass('inactive').addClass('active');
		});

	}	

});
// ----- transition form js ends here -------